export function valueValidate (rule, value, callback) {
  if (value.trim() === '') {
    callback(new Error('请输入信息'))
  } else {
    let flag = false
    var specialKey = "[`~!#$^&*()=|{}':;'\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'"
    for (var i = 0; i < value.length; i++) {
      if (specialKey.indexOf(value.substr(i, 1)) !== -1) {
        flag = true
      }
    }
    if (flag) {
      callback(new Error('输入信息包含特殊字符'))
    } else {
      callback()
    }
  }
}

// 非负、非0数字验证
export function validNonZero (rule, value, callback) {
  if (!Number(value)) {
    callback(new Error('请输入非0数字'))
  } else {
    setTimeout(() => {
      const re = /^\d*|[+-]?((0|([1-9]\d*))\.\d+)?$/
      const rsCheck = re.test(value)
      if (!rsCheck && value > 0) {
        callback(new Error('请输入正数'))
      } else {
        callback()
      }
    }, 500)
  }
}
// 整数验证
export function validInteger (rule, value, callback) {
  if (value === '' || value === null) {
    return callback(new Error('不可以为空'))
  } else {
    setTimeout(() => {
      const re = /^\d*$/
      const rsCheck = re.test(value)
      if (!rsCheck) {
        callback(new Error('请输入0或者正整数'))
      } else {
        callback()
      }
    }, 500)
  }
}
// 正整数验证
export function validPosNumber (rule, value, callback) {
  if (value === '' || value === null) {
    return callback(new Error('输入不可以为空'))
  }
  setTimeout(() => {
    if (!Number(value)) {
      callback(new Error('请输入正整数'))
    } else {
      const re = /^[0-9]*[1-9][0-9]*$/
      const rsCheck = re.test(value)
      if (!rsCheck) {
        callback(new Error('请输入正整数'))
      } else {
        callback()
      }
    }
  }, 500)
}
// 邮箱验证
export function validateEMail (rule, value, callback) {
  const reg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/
  if (value === '' || value === undefined || value === null) {
    callback()
  } else {
    if (!reg.test(value)) {
      callback(new Error('请输入正确的邮箱'))
    } else {
      callback()
    }
  }
}
// 身份证验证
export function validateIdNo (rule, value, callback) {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  if (value === '' || value === undefined || value === null) {
    callback()
  } else {
    if ((!reg.test(value)) && value !== '') {
      callback(new Error('请输入正确的身份证号码'))
    } else {
      callback()
    }
  }
}
// 验证固话或者手机导电话
export function validatePhoneTwo (rule, value, callback) {
  const reg = /^((0\d{2,3}-\d{7,8})|(1[345789]\d{9}))$/
  if (value === '' || value === undefined || value === null) {
      callback(new Error('请输入手机号码'))
  } else {
    if ((!reg.test(value)) && value !== '') {
      callback(new Error('请输入正确的手机号码'))
    } else {
      callback()
    }
  }
}

// 验证密码 8-16位字母数字字符，不能纯数字字母
export function validatePassword (rule, value, callback) {
  const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
  if (value === '' || value === undefined || value === null) {
      callback(new Error('请输入密码'))
  } else {
    if ((!reg.test(value)) && value !== '') {
      callback(new Error('请输入8-16位字母数字，不能纯数字字母'))
    } else {
      callback()
    }
  }
}

export function parseTime (time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear() || new Date().getFullYear(),
    m: date.getMonth() + 1 || new Date().getMonth() + 1,
    d: date.getDate() || new Date().getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return timeStr
}