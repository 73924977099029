<template>
  <el-row>
		<!-- APP端 -->
		<div v-if="isApp" class="app-el-form app-form-box">
			<el-form
			  :rules="rules"
			  ref="ruleForm"
			  label-position="left"
			  label-width="0px"
			  :model="forData"
			>
				<el-form-item prop="name">
				  <el-input
				    @blur="validChange(1, $event)"
				    placeholder="请输入组织名称"
				    v-model="forData.name"
				  ></el-input>
				</el-form-item>
				<el-form-item prop="company_code">
				  <el-input
				    @blur="validChange(2, $event)"
				    placeholder="请输入社会统一信用代码"
				    v-model="forData.company_code"
				  ></el-input>
				</el-form-item>
				<el-form-item prop="legal_name">
				  <el-input
				    placeholder="请输入法人姓名"
				    v-model="forData.legal_name"
				  ></el-input>
				</el-form-item>
				<div :class="['wramings', showWarimg ? '' : 'hiddens']" >
				  <div>
				    <i class="el-icon-warning"></i>
				  </div>
				  <div >
				    该企业已经在翡云入驻，请联络该企业创建人，若企业被冒用请拨打客服电话。
				  </div>
				</div>
				<el-form-item v-if="agreeList&&agreeList.length>0">
					<div class="agreement-box">
						<div class="check-box">
							<el-checkbox v-model="forData.is_read_agree"></el-checkbox>
						</div>
						<p class="agree-box">
							我已阅读并同意<span v-for="(item,index) in agreeList" :key="index">
								<span class="agree" @click="agreeBtn(item)">《{{item.title}}》</span>
								<template v-if="index<agreeList.length-1">和</template>
							</span>
						</p>
					</div>
				</el-form-item>
		
				<div class="btns">
				  <el-button
				    :disabled="!forData.is_read_agree || showWarimg"
				    @click="submit"
				    style="width: 100%;font-size: 1.4em;line-height: 1.5;border-radius: .2em;"
				    type="danger"
				    >注册新企业</el-button
				  >
				</div>
			
			</el-form>
		</div>
		<!-- PC端 -->
    <el-form v-else
      :rules="rules"
      ref="ruleForm"
      label-position="left"
      label-width="0px"
      :model="forData"
    >
      <el-col :span="24">
        <el-form-item prop="name">
          <el-input
            @blur="validChange(1, $event)"
            placeholder="请输入组织名称"
            v-model="forData.name"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item prop="company_code">
          <el-input
            @blur="validChange(2, $event)"
            placeholder="请输入社会统一信用代码"
            v-model="forData.company_code"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item prop="legal_name">
          <el-input
            placeholder="请输入法人姓名"
            v-model="forData.legal_name"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :class="['wramings', showWarimg ? '' : 'hiddens']" :span="24">
        <div>
          <i class="el-icon-warning"></i>
        </div>
        <div>
          该企业已经在翡云入驻，请联络该企业创建人，若企业被冒用请拨打客服电话。
        </div>
      </el-col>
      <el-col :span="24" class="btns">
        <el-button
          :disabled="!forData.is_read_agree || showWarimg"
          @click="submit"
          style="width: 100%;font-size: 18px;"
          type="danger"
          >注册新企业</el-button
        >
      </el-col>
      <el-col :span="24">
        <el-form-item v-if="agreeList&&agreeList.length>0">
        <div class="agreement-box">
          <div class="check-box">
            <el-checkbox v-model="forData.is_read_agree">
            </el-checkbox>
          </div>
          <p class="agree-box">
            我已阅读并同意<span v-for="(item,index) in agreeList" :key="index">
              <span class="agree" @click="agreeBtn(item)">《{{item.title}}》</span>
              <template v-if="index<agreeList.length-1">和</template>
            </span>
          </p>

        </div>

        </el-form-item>
      </el-col>
    </el-form>
  </el-row>
</template>

<script>
import { postJSON } from "@/server/ajax.js";
import { parseTime } from '@/utils/validate.js';
import { isMobile } from "@/utils/wxConfig.js";
const URL = {
  VALID: "/base/company/isexist", // 验证企业是否入驻
  REGISTER: "/api/users/company_station/register", // 提交注册
};
export default {
  props: {
    params:{
      type:Object,
      default:()=>{
        return {
          id:0
        }
      }
    },
    keys:{
      type:Object,
    },
  },
  data() {
    return {
      showWarimg: false,
			isApp:false,
      forData: {
        name: "",
        company_code: "",
        legal_name: "",
        is_read_agree: true,
        company_type:0,
      },
      message: {
        company_name: "",
        name: "",
        phone: "",
        times: "",
      },
      agreeList:[],
      rules: {
        name: [
          { required: true, message: "请输入组织名称", trigger: "blur" },
        ],
        company_code: [
          {
            required: true,
            trigger: "blur",
            validator:(rules,value,call)=>{
            	if(value){
            		// if(!/^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g.test(value)){
            		if (!/^[0-9A-Z]{18}$/g.test(value)) {
            			call(new Error("请输入正确的社会统一信用代码"))
            		}else{
            			call();
            		}
            	}else{
            		call(new Error('请输入社会统一信用代码'))
            	}
            }
          },
        ],
        legal_name: [
          { required: true, message: "请输入法人姓名", trigger: "blur" },
        ],
      },
    };
  },
  created() {
		this.isApp = isMobile();
    this.message.name = this.keys.real_name;
    this.message.phone = this.keys.mobile;
    this.message.times = parseTime(new Date(),'{y}-{m}-{d}');
    this.getAgreeList();
    if(this.params && this.params.id){
      this.initParams()
    }
  },
  methods: {
    agreeBtn(row){
      let routeUrl = this.$router.resolve({
        path:'/register/agreement',
        query:{
          id:row.id,
        }
      })
      window.open(routeUrl.href, '_blank');
    },
    getAgreeList(){ //入驻协议
      this.$axios.get('/setting/agreement/station_list').then(res=>{
        if(res.code==200){
          let data = res.data;
          if(data && data.length>0){
            this.agreeList = res.data;
          }else{
            this.fromData.is_read_agree = true;
          }
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    initParams(){
      let params = this.params;
      this.forData.name = params.invite_name;
      this.forData.company_code = params.invite_code;
      this.forData.company_type = params.invite_type;
    },
    // 立即注册
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$emit('isok',this.forData);
        } else {
          this.$message({
            message: "请填写完整的信息",
            type: "warning",
          });
        }
      });
    },
    validChange(val, event) {
      let params = {},nume = event.target.value;
      if (val == 1) {
        params.name = nume;
        this.message.company_name = nume;
      } else {
        params.company_code = nume;
      }
      postJSON(URL.VALID, params).then(
        (res) => {
          if(res.data){
            this.showWarimg = true;
          }else{
            this.showWarimg = false;
          }
        },
        (err) => {
          this.showWarimg = true;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.wramings {
  margin-top: 10px;
  display: flex;
  & > div:nth-child(1) {
    font-size: 22px;
    color: red;
    margin-right: 10px;
  }
  & > div:nth-child(2) {
    font-size: 14px;
    color: red;
    line-height: 22px;
  }
}

.agreement-box{
  margin-top: 10px;
  display: flex;
  line-height: 1.35em;
  .check-box{
    margin-right:8px;
  }
}
.messages {
  margin-top: 20px;
  p {
    font-weight: 800;
    line-height: 22px;
  }
  margin-bottom: 40px;
}
.btns{
  margin-top:150px;
}
.agree-box{
  color: #666666;
  .agree{
    color:#F26161;
    cursor: pointer;
  }
}
.hiddens {
  visibility: hidden;
}
/deep/ .el-input__inner:focus {
  border-color: red !important;
}

//APP端样式问题
.app-form-box{
	padding:0 1.5em;
	padding-top:2em;
	.agree-info{
		font-size: 1.4rem;
	}
	.wramings{
		height: 4rem;
		margin-top:0;
		div{
			font-size: 1.3rem;
			line-height: 1.4;
		}
		
	}
	.agree-box{
		margin-top: .2em;
	}
	.agreement-box{
		.agree-box{
			font-size: 1em;
		}
	}
	.btns{
		position: fixed;
		left:3em;
		width:31.5em;
		bottom:2em;
	}
}


</style>
