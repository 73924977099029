<template>
<div class="stage">
	<div class="slider" id="slider">
		<div class="label">向右滑动验证</div>
		<div class="track" id="track">
			<div class="bg-green" id="bg-green"></div>
		</div>
		 <!--  -->
		<div class="button" id="btn" @touchstart="start($event)" @touchmove.prevent="move($event)" @touchend="end($event)">
			<div class="icon" id="icon"></div>
			<div class="spinner" id="spinner"></div>
		</div>
	</div>
</div>
</template>

<script>
	export default {
		props:{
			mobile:{
				type:String|Number,
				default:'',
			}
		},
		data(){
			return {
				rem:0,
				flag:1,
				time1:new Date().getTime(),
				oW:0,
				oLeft:0,
			}
		},
		created(){
			this.rem = parseInt(document.body.clientWidth/750 * 20 *100)/100;
		},
		mounted(){
			this.slideBtn();
		},
		methods: {
			resets(){
				var oBtn = document.getElementById('btn');
				var oTrack=document.getElementById('track');
				this.flag = true;
				oBtn.style.left = 0;
				oTrack.style.width= 0;
			},
			start(event){
				if(!this.flag){ return false; }
				var oBtn = document.getElementById('btn');
				var oW,oLeft;
				var oTrack=document.getElementById('track');
				let flag = this.flag;
				let _this = this, mobile = this.mobile; 
				if(!_this.mobile){
					_this.$message.error("请输入手机号");
					flag = 0;
				}else if(!/^1[3-9]\d{9}$/.test(mobile)){
					_this.$message.error("请输入正确的手机号");
					flag = 0;
				}else{
					flag = 1;
				}
				if(flag==1){
					_this.time1 = new Date().getTime();	
					let e = event.target;
					var touches = event.touches[0];
					_this.oW = touches.clientX - oBtn.offsetLeft;
					oBtn.className="button";
					oTrack.className="track";
				}
			},
			slideBtn(){
				    
			
			},
			move(event){
				if(!this.flag){ return false; }
				var oBtn = document.getElementById('btn');
				var oTrack=document.getElementById('track');
				let e = event.target;
				// console.log(event);
				let oLeft = this.oLeft,oW = this.oW, flag = this.flag;
				let btnWidth  = parseInt(this.rem * 3);
				if(flag==1){
					var touches = event.targetTouches[0];
					oLeft = touches.clientX - oW;
					if(oLeft < 0) {
						oLeft = 0;
					}else if(oLeft > document.documentElement.clientWidth - oBtn.offsetWidth - btnWidth) {
						oLeft = (document.documentElement.clientWidth - oBtn.offsetWidth - btnWidth);
					}
					this.oLeft = oLeft;
					oBtn.style.left = oLeft + "px";
					oTrack.style.width=oLeft+ 'px';		
				}
			},
			end(){
				if(!this.flag){ return false; }
				var oBtn = document.getElementById('btn');
				var oW = this.oW,oLeft = this.oLeft;
				var oSlider=document.getElementById('slider');
				var oTrack=document.getElementById('track');
				var oIcon=document.getElementById('icon');
				var oSpinner=document.getElementById('spinner');
				var bgGreen=document.getElementById('bg-green');
				let flag = this.flag;
				let btnWidth  = parseInt(this.rem * 3);
				let btnEndLeft = parseInt(this.rem * 4.5);
				let _this = this,mobile = String(this.mobile),val=true;
				if(!_this.mobile){
					val = false;
				}else if(!/^1[3-9]\d{9}$/.test(mobile)){
					val = false;
				}
				if(!val){
					oBtn.style.left = '0px';
					oTrack.style.width= '0px';
					_this.$emit("isOk", { isOk: false });
					return false;
				}
				if(oLeft>=(oSlider.clientWidth-oBtn.clientWidth - btnWidth/2)){
					oBtn.style.left = (oSlider.clientWidth - btnWidth)+'px';
					oTrack.style.width=   (document.documentElement.clientWidth - oBtn.offsetWidth - btnWidth) + 'px';
					oIcon.style.display='none';
					oSpinner.style.display='block';
					bgGreen.innerHTML='验证成功'
					_this.flag = false;
					_this.$emit("isOk", {
						data1:{x: 392, y: 338,time:_this.time1},
						data2:{x: 784,y: 339,time:new Date().getTime()},
						isOk: true,
					});
				}else{
				    oBtn.style.left = 0;
				    oTrack.style.width= 0;
						_this.$emit("isOk", { isOk: false });
				}
				oBtn.className="button-on";
				oTrack.className="track-on";  
			},
		},
	}
</script>

<style scoped lang="scss">
.stage{
	position:relative;
	height:3em;
	width: 100%;
	font-size: calc(100vw/750*20);
}
.slider{
	position:absolute;
	height:3em;
	box-shadow:0 0 3px #999;
	background-color:#ddd;
	left:0;
	right:0;
}
.label {
    background: -webkit-gradient(linear, left top, right top, color-stop(0, #4d4d4d), color-stop(.4, #4d4d4d), color-stop(.5, white), color-stop(.6, #4d4d4d), color-stop(1, #4d4d4d));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: slidetounlock 3s infinite;
    -webkit-text-size-adjust: none;
    line-height: 2em;
    height: 3em;
    text-align: center;
    font-size:1.5em;
    width: 100%;
    color: #aaa;
}
@keyframes slidetounlock
{
    0%     {background-position:-200px 0;}
    100%   {background-position:200px 0;}
}
@-webkit-keyframes slidetounlock
{
    0%     {background-position:-200px 0;}
    100%   {background-position:200px 0;}
}
.button{
    position: absolute;
    left: 0;
    top: 0;
    width: 3em;
    height: 3em;
    background-color: #fff;
    transition: left 0s;
    -webkit-transition: left 0s;
}
.button-on{
    position: absolute;
    left: 0;
    top: 0;
    width: 3em;
    height: 3em;
    background-color: #fff;
    transition: left 1s;
    -webkit-transition: left .5s;
}
.track{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    overflow: hidden;
    transition: width 0s;
    -webkit-transition: width 0s;
}
.track-on{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    overflow: hidden;
    transition: width 1s;
    -webkit-transition: width .5s;
}
.icon  {
    width: 3em;
    height: 3em;
    position: relative;
    font-family: sans-serif;
		background-color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
}
.icon:before{
    content:'>>';
    color:#ccc;
    line-height:3em;
}
.spinner {
    width: 3em;
    height: 3em;	
		// background: url('../images/ok.png') no-repeat;
		// background: yellowgreen;
    position: relative;
    top:1px;
    left:0px;
    display: none;
}
 
@-webkit-keyframes bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0.0) }
    40% { -webkit-transform: scale(1.0) }
}
@keyframes bouncedelay {
    0%, 80%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 40% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
}
.bg-green {
    line-height: 2em;
    height: 3em;
    text-align: center;
    font-size:1.5em;
    background-color: #78c430;
    color: #fff;
}
.sub{
    width: calc(100% - 80px);
    margin-left: 40px;
    margin-top: 30px;
    height: 45px;
    background:#78c430;
    color: #fff;
    outline: none;
    border: none;
    font-size: 16px;
}
</style>
