<template>
  <div class="settled"  :class="{app:isApp}">
		<div v-if="isApp" class="regesit-app">
			<div class="titles">
			  <div @click="goto(1)" class="stepColor">管理员注册</div>
			  <div :class="status > 1 ? 'stepColor' : ''">—</div>
			  <div @click="goto(2)" :class="status > 1 ? 'stepColor' : ''">
			    创建企业
			  </div>
			  <div :class="status > 2 ? 'stepColor' : ''">—</div>
			  <div @click="goto(3)" :class="status > 2 ? 'stepColor' : ''">
			    平台审核
			  </div>
			  <div :class="[status > 3 ? 'stepColor' : '',error ?'redColor':'']">—</div>
			  <div @click="goto(4)" :class="[status > 3 ? 'stepColor' : '',error ?'redColor':'']">
			   {{error?'入驻失败':'入驻成功'}}
			  </div>
			</div>
			<div class="fromDate">
			  <!-- 管理员的 -->
			  <administrators @postKey="postKey" :key="invited_key" :params="invited_info" v-if="status == 1" />
			  <!-- 创建企业 -->
			  <createEnterprise @isok="isok" :key="invited_key" :params="invited_info"  :keys="keys" v-if="status == 2" />
			  <!-- 平台审核 -->
			  <examine :message="examineMsg" v-if="status == 3" />
			  <!-- 审核状态 -->
			  <examineStatus
			    @registerAgain="registerAgain"
			    :message="message"
			    v-if="status == 4"
			  />
			</div>
		</div>
    <div class="regesit" v-else>
      <div class="titles">
        <div @click="goto(1)" class="stepColor">①管理员注册</div>
        <div :class="status > 1 ? 'stepColor' : ''">—</div>
        <div @click="goto(2)" :class="status > 1 ? 'stepColor' : ''">
          ②创建企业
        </div>
        <div :class="status > 2 ? 'stepColor' : ''">—</div>
        <div @click="goto(3)" :class="status > 2 ? 'stepColor' : ''">
          ③平台审核
        </div>
        <div :class="[status > 3 ? 'stepColor' : '',error ?'redColor':'']">—</div>
        <div @click="goto(4)" :class="[status > 3 ? 'stepColor' : '',error ?'redColor':'']">
         {{error?'④入驻失败':'④入驻成功'}}
        </div>
      </div>
      <div class="fromDate">
        <!-- 管理员的 -->
        <administrators @postKey="postKey" :key="invited_key" :params="invited_info" v-if="status == 1" />
        <!-- 创建企业 -->
        <createEnterprise @isok="isok" :key="invited_key" :params="invited_info"  :keys="keys" v-if="status == 2" />
        <!-- 平台审核 -->
        <examine :message="examineMsg" v-if="status == 3" />
        <!-- 审核状态 -->
        <examineStatus
          @registerAgain="registerAgain"
          :message="message"
          v-if="status == 4"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { get ,postJSON} from "@/server/ajax.js";
const URLS = {
  STATUS: "/api/users/company_station/status",
};
import { wxShareConfige, shareWXQQ, isMobile } from "@/utils/wxConfig.js";
import administrators from "./components/administrators.vue";
import createEnterprise from "./components/createEnterprise.vue";
import examine from "./components/examine.vue";
import examineStatus from "./components/examineStatus.vue";
export default {
  name: "settled",
  components: {
    administrators,
    createEnterprise,
    examine,
    examineStatus,
  },
  data() {
    return {
      error:false,
      status: 1,
			isApp:false,
      id:0,
      keys: {},
      examineMsg: {}, // 平台审核的状态
      message: {}, // 审核状态的状态值
      admin_info:{},
      company_info:{},
      company_type:1,
      invited_info:{},
      invited_key:0,
      stepArray: [
        {
          id: 1,
          name: "①管理员注册",
        },
        {
          id: 2,
          name: "②创建企业",
        },
        {
          id: 3,
          name: "③平台审核",
        },
        {
          id: 4,
          name: "④入驻成功",
        },
      ],
    };
  },
  created() {
		this.isApp = isMobile();
    let code = this.$route.query.code;
    if (code) { //查询审核状态
      get(URLS.STATUS, { code }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          if (res.data.status == 0) {
            // 0代表带审核
            this.status = 3;
            this.examineMsg = {
              apply_date: res.data.created_at,
              serial_number: res.data.serial_number,
              encode: code,
            };
          } else if (res.data.status == 2) {
            this.error = false;
            this.status = 4;
            this.message = res.data;
          } else if (res.data.status == 3) {
            this.error = true;
            this.status = 4;
            this.message = res.data;
          }
        }
      });
    }
    let query = this.$route.query;
    if(query.k){
      this.id = query.k;
      this.getPlatData();
      this.activeLink(query.k)
    }
  },
	async mounted() {
		let wxConfig = await wxShareConfige();
		if(this.$route.query.k) {
		  this.$axios.postJSON('/base/invite/detail',{serial_number:this.$route.query.k}).then((res) => {
		    if(res.code == 200) {
					//配置微信分享
					if (wxConfig === "1") {
					  shareWXQQ(res.data.invite_set);
					}
		    }
		  })
		}
	},
  methods: {
    postKey(val) {
      this.admin_info = val;
      this.company_type = val.company_type;
      this.status = 2;
    },
    activeLink(link){
      this.$axios.postJSON('/base/invite/active',{serial_number:link}).then(res=>{
        if(res.code == 200){
         console.log('激活成功');
        }
      }).catch(err=>{
        err.msg && this.$message.error(err.msg);
      })
    },
    getPlatData(){ //获取运营方邀请数据
      this.$axios.postJSON('/base/invite/detail',{serial_number:this.id}).then(res=>{
        if(res.code == 200){
          let data = res.data;
          this.invited_info= data;
          this.invited_key = + new Date();
          
        }
      }).catch(err=>{
        console.log(err);
        err.msg && this.$message.error(err.msg);
      })
    },
    goto(val) {
      if(val <= this.status){
          this.status = val;
      }
    },
    isok(val) {
      let query = this.$route.query;
      this.admin_info.company_type = this.company_type;
      let data = {
        is_read_agree:true,
        admin_info:this.admin_info,
        company_info:val,
        mobile_verity_code:this.admin_info.valid_code,
      }
      if(query.k){
        data.invite_serial_number = query.k;
      }
      postJSON('/base/company/station',data).then(res=>{
        if(res.code == 200){
          this.status = 3;
          let company = res.data.company;
          company.status = 2;
          company.admin_name = this.admin_info.true_name;
          company.admin_mobile = this.admin_info.mobile;
          this.examineMsg = company;
        }
      }).catch()

      this.examineMsg = val;
    },
    // 进入重新注册
    registerAgain() {
      this.status = 1;
      this.error = false;
    },
  },
  destroyed() {
    clearInterval(this.times);
  },
};
</script>

<style lang="scss" scoped>
.settled {
  width: 1200px;
  margin: 10px auto;
  margin-top:32px;
  & > div {
    width: 600px;
    margin:0 auto;
    display: block;
  }
  .regesit {
    height: 640px;
    background-color: #fff;
    padding: 30px;
    box-sizing: border-box;
    .titles {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      margin-bottom: 40px;
      color:#999999;
    }
    .stepColor {
      color: #4387f8;
      user-select: none;
      &:nth-of-type(odd) {
        cursor: pointer;
      }
    }
    .fromDate {
      padding: 0px 60px;
    }
    .redColor{
      color: red;
    }
  }
}
.settled.app{
	font-size: calc(100vw / 750 * 20);
	width:100vw;
	margin:0 1.5em;
	display: block;
	& >div{
		width:100%;
	}
	.regesit-app{
		background-color: #ffffff;
		position: relative;
		top:-2em;
		border-radius: 1em 1em 0 0;
		.titles{
			display: flex;
			font-size: 1.5em;
			padding:.2em .3em;
			padding-top:1em;
			justify-content: center;
			color:#999999;
			.stepColor{
				color:#4387F8;
			}
			.line{
				margin:0 .5em;
			}		
		}
	}
}
</style>
<style lang="scss">
	.app-el-form{
		font-size: calc(100vw/750 * 20);
		// el-form组件代码
		.el-form-item{
			margin-bottom: 3rem;
		}
		.el-form-item__content{
			line-height: 1em;
			font-size: 1.4rem;
		}
		.el-input{
			font-size: 1.4rem;
		}
		.el-input__inner{
			line-height:1;
			font-size: 1.4rem;
			height: 2.5em;
			padding-left:1rem;
		}
		.el-form-item--small .el-form-item__error{
			padding-top:.5rem;
		}
		.el-form-item__error{
			font-size: 1.3rem;
		}
		.el-button{
			border-radius: .2em;
		}
		.el-button--danger{
			line-height: 1.75;
			font-size: 1.4rem;
		}
		.el-checkbox{
			font-size: 1.2rem;
			margin-right: .4em;
		}
		.el-checkbox__inner{
			width: 1.4rem;
			height: 1.4rem;
			&::after{
				width:.5rem;
				height:.7rem;
				border:.2rem solid #FFFFFF;
				border-left:0;
				border-top:0;
				left:.3rem;
			}
		}
	}
</style>
