<template>
  <el-row>
		<!-- 手机端 -->
		<div v-if="isApp" class="app-status">
			<div class="examin">
			  <p><i class="el-icon-success icon-succ"></i>提交成功，平台审核中</p>
			</div>
			<div class="messages">
			  <p>提交时间：{{message.station_date}}</p>
			  <p>
			    平台已收到您提交的信息，等耐心等待，人工审核时间为2个工作日。
			  </p>
			</div>
			<div class="showImgs">
			  <el-button style="width: 100%;font-size: 1.4em;line-height: 1.5;border-radius: .2em;" type="danger" >
					打开电脑端，进入工作台
				</el-button>
			</div>
		</div>
		<!-- PC端 -->
		<div v-else>
			<el-col class="examin" :span="24">
			  <p><i class="el-icon-success"></i>提交成功，平台审核中</p>
			</el-col>
			
			<el-col class="messages" :span="24">
			  <p>提交时间：{{message.station_date}}</p>
			  <p>
			    平台已收到您提交的信息，等耐心等待，人工审核时间为2个工作日。
			  </p>
			</el-col>
			
			<el-col class="showImgs" :span="24">
			  <el-button style="width: 100%;font-size: 18px;" type="danger"
			    @click="homeBtn"
			    >平台首页</el-button
			  >
			  <div class="login-msg">
			    <span class="btn"  @click="loginBtn">管理员账号登录工作台</span>
			  </div>
			</el-col>
		</div>
    
  </el-row>
</template>

<script>
import { isMobile } from "@/utils/wxConfig.js";
export default {
  props: ["message"],
	data:{
		isApp:false,
	},
  created(){
    this.isApp = isMobile();
  },
  methods: {
    copyLink() {
        this.copy('https://user.'+ VUE_APP_API_URL +'/#/register/settled?code='+this.message.encode)
    },
    loginBtn(){
      this.$router.push({
        path:'/login'
      })
    },
    homeBtn(){ //跳转首页工作台
      this.$utils.goIndexPage();
    },
    copy(data) {
      // 存储传递过来的数据
      let OrderNumber = data;
      // 创建一个input 元素
      // createElement() 方法通过指定名称创建一个元素
      let newInput = document.createElement("input");
      // 讲存储的数据赋值给input的value值
      newInput.value = OrderNumber;
      // appendChild() 方法向节点添加最后一个子节点。
      document.body.appendChild(newInput);
      // 选中input元素中的文本
      // select() 方法用于选择该元素中的文本。
      newInput.select();
      // 执行浏览器复制命令
      //  execCommand方法是执行一个对当前文档，当前选择或者给出范围的命令
      document.execCommand("Copy");
      // 清空输入框
      newInput.remove();
      // 下面是element的弹窗 不需要的自行删除就好
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.examin {
  font-size: 28px;
  color: #7ac756;
}
.messages {
  & > p:nth-child(1) {
    margin-top: 20px;
    color: #999;
    font-size: 12px;
    line-height: 30px;
  }
  & > p:nth-child(2) {
    color: #999;
    font-size: 12px;
    line-height: 16px;
  }
  & > p:nth-child(3) {
    color: #333;
    font-size: 12px;
    font-weight: 800;
    line-height: 30px;
    margin-bottom: 40px;
  }
  & > p:nth-child(4) {
    display: flex;
    justify-content: space-between;
    color: #333;
    font-size: 12px;
    font-weight: 800;
    line-height: 30px;
    margin-bottom: 40px;
    & > span:nth-child(2) {
      width: 90px;
      display: inline-block;
      color: #4387f8;
      user-select: none;
      cursor: pointer;
    }
  }
}
.showImgs {
  margin-top: 320px;

}
.login-msg{
  text-align: right;
  margin-top: 4px;
  .btn{
    cursor: pointer;
    color: #4387f8;
  }
}
.app-status{
	font-size: calc(100vw/750 * 20);
	padding-top:2em;
	padding-bottom:2em;
	.examin{
		font-size: 1.8em;
		text-align: left;
		padding-left:2.25em;
		position: relative;
		.icon-succ{
			position: absolute;
			left:1em;
			top:50%;
			transform: translateY(-50%);
		}
	}
	.messages{
		padding:0 1.5em;
		margin-top: 1.4em;
		p{
			padding-left:2.3em;
			font-size: 1.2em;
			line-height: 1.75;
			height: auto;
		}
	}
	.showImgs{
		position: fixed;
		left:3em;
		width:31.5em;
		bottom:2em;
	}
}
</style>
